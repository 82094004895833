.more-menu-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.more-menu-container {
  background: white;
  width: 300px;
  border-radius: 8px;
  padding: 20px;
}

.menu-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}

.menu-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  cursor: pointer;
}

.icon {
  width: 40px;
  height: 40px;
  background-color: #e8f4ff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 8px;
  color: #0084ff;
  font-size: 20px;
}

.menu-item span {
  color: #666;
  font-size: 14px;
  line-height: 1.2;
}
