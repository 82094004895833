.invoice-container {
  max-width: 800px;
  width: 95%;
  margin: 80px auto 40px;
  padding: 20px;
  background-color: #fff;
  font-family: "Arial", sans-serif;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.invoice-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px solid #333;
  padding-bottom: 20px;
}

.invoice-title h1 {
  font-size: 28px;
  color: #333;
  font-family: "Georgia", serif;
  margin-bottom: 5px;
}

.invoice-title p {
  font-size: 14px;
  color: #555;
}

.chelto-logo {
  max-width: 150px;
}

.gst-number {
  font-size: 12px;
  color: #555;
  margin-top: 5px;
}

.invoice-details {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  margin: 30px 0;
}

.invoice-meta,
.customer-info {
  font-size: 14px;
  color: #333;
}

.customer-info h2 {
  font-size: 18px;
  color: #333;
  margin-bottom: 10px;
}

.customer-info p {
  font-size: 14px;
  margin: 2px 0;
  color: #555;
}

.invoice-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  font-size: 14px;
}

.invoice-table th,
.invoice-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.invoice-table th {
  background-color: #f5f5f5;
  font-weight: bold;
}

/* Add responsive table styles */
@media screen and (max-width: 768px) {
  .invoice-table {
    font-size: 12px;
  }

  .invoice-table th,
  .invoice-table td {
    padding: 6px 4px;
  }
}

@media screen and (max-width: 480px) {
  .invoice-table {
    display: block;
    overflow-x: auto;
    white-space: nowrap;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  }

  .invoice-table th,
  .invoice-table td {
    padding: 8px 6px;
    min-width: 80px;
  }

  .invoice-table th:first-child,
  .invoice-table td:first-child {
    position: sticky;
    left: 0;
    background-color: #fff;
    z-index: 1;
  }

  .invoice-table th:first-child {
    background-color: #f5f5f5;
  }
}

.invoice-summary {
  margin-top: 20px;
  padding: 15px;
  background-color: #f8f8f8;
  border: 1px solid #ddd;
  border-radius: 6px;
}

.summary-row {
  display: flex;
  justify-content: space-between;
  padding: 8px 0;
  font-size: 14px;
}

.summary-row.total {
  font-size: 16px;
  font-weight: bold;
  color: #333;
  border-top: 2px solid #ddd;
  padding-top: 12px;
}

.payment-details {
  margin-top: 25px;
}

.payment-details h2 {
  font-size: 18px;
  color: #333;
  border-bottom: 2px solid #333;
  padding-bottom: 8px;
}

.payment-details p {
  font-size: 14px;
  margin: 6px 0;
  color: #555;
}

.invoice-footer {
  margin-top: 30px;
  text-align: center;
  font-size: 12px;
  color: #777;
}

.invoice-footer p {
  margin: 4px 0;
}

.download-button {
  background-color: #4caf50;
  color: white;
  padding: 10px 20px;
  margin: 20px auto;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  display: block;
  width: 200px;
  max-width: 100%;
}

.download-button:hover {
  background-color: #45a049;
}