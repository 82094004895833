@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.1.1/css/all.min.css");

header {
  width: 100%;
  background-color: #ffffff;
  background-image: linear-gradient(to right,
      rgba(255, 153, 51, 0.1),
      rgba(255, 255, 255, 0.1),
      rgba(19, 136, 8, 0.1));
  background-attachment: fixed;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  height: 90px;
  padding: 10px 0;
}

.container-fluid {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 30px;
}

.header-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 70px;
  gap: 20px;
}

.logo-search-container {
  display: flex;
  align-items: center;
  gap: 25px;
  flex: 1;
}

.logo-container {
  flex: 0 0 auto;
  padding: 5px 0;
  display: flex;
  align-items: center;
}

.logo {
  max-width: 140px;
  height: auto;
  display: block;
  margin: 0;
}

.navigation-container {
  flex: 0 0 auto;
}

.navigation-container ul {
  display: flex;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.navigation-container li {
  margin-left: 20px;
}

.navigation-container a {
  text-decoration: none;
  color: #333;
  font-size: 16px;
  font-weight: 500;
  transition: color 0.3s ease;
  display: flex;
  align-items: center;
}

.navigation-container a i {
  margin-right: 5px;
}

.navigation-container a:hover {
  color: #c70e70;
}

.navigation-container ul li a.active {
  color: green;
}

.mobile-nav {
  display: none;
}

.desktop-only {
  display: block;
}

@media (max-width: 768px) {
  body {
    padding-bottom: 65px;
  }

  header {
    height: 70px;
    padding: 5px 0;
  }

  .header-content {
    flex-wrap: nowrap;
    height: 60px;
    padding: 0;
    justify-content: space-between;
    gap: 10px;
  }

  .logo-search-container {
    flex: 1;
    gap: 15px;
  }

  .logo-container {
    flex: 0 0 auto;
    text-align: left;
    margin-bottom: 0;
    margin-right: 10px;
    padding: 0;
  }

  .logo {
    max-width: 120px;
  }

  .navigation-container {
    display: none;
  }

  .mobile-nav {
    display: flex;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #ffffff;
    box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.1);
    z-index: 1000;
  }

  .mobile-nav ul {
    display: flex;
    justify-content: space-around;
    width: 100%;
    padding: 5px 0;
    margin: 0;
    list-style-type: none;
  }

  .mobile-nav li {
    flex: 1;
    text-align: center;
  }

  .mobile-nav a {
    text-decoration: none;
    color: #333;
    font-size: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 5px 2px;
    transition: color 0.3s ease;
  }

  .mobile-nav i {
    font-size: 16px;
    margin-bottom: 2px;
  }

  .mobile-nav a.active {
    color: green;
  }

  .desktop-only {
    display: none;
  }
}

@media (max-width: 320px) {
  .mobile-nav a {
    font-size: 9px;
  }

  .mobile-nav i {
    font-size: 14px;
  }

  .logo {
    max-width: 60px;
  }

  .search-container {
    flex: 0 1 120px;
  }
}

.location-container {
  position: relative;
  margin: 0 15px;
}

.location-button {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  background: #ffffff;
  border: 1px solid #e5e7eb;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.2s ease;
  min-width: 200px;
}

.location-button:hover {
  background: #f9fafb;
  border-color: #d1d5db;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.location-icon {
  color: #dc2626;
  font-size: 16px;
}

.location-details {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1;
}

.location-label {
  font-size: 11px;
  color: #6b7280;
  font-weight: 500;
  line-height: 1;
  margin-bottom: 2px;
}

.location-value {
  font-size: 13px;
  color: #111827;
  font-weight: 600;
  line-height: 1.2;
  max-width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.location-arrow {
  color: #9ca3af;
  font-size: 12px;
  transition: transform 0.2s ease;
}

.location-button:hover .location-arrow {
  transform: translateY(2px);
}

@media (max-width: 768px) {
  .location-button {
    min-width: auto;
    padding: 6px 12px;
  }

  .location-label {
    display: none;
  }

  .location-value {
    font-size: 12px;
    max-width: 120px;
  }
}

.background-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
}

.search-results-dropdown {
  position: absolute;
  background-color: white;
  border: 1px solid #ccc;
  width: 89%;
  max-height: 300px;
  overflow-y: auto;
  margin-left: 18px;
  margin-right: 150px;
  z-index: 1000;
  left: 0;
  margin: 60;
  padding: 0;
}

.search-result-item {
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  border-bottom: 1px solid #eee;
  height: 100px;
}

.search-result-image {
  width: 100px;
  height: 100px;
  margin-right: 10px;
}


.search-result-details {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.download-app-link {
  color: green;
}

.more-box {
  position: absolute;
  width: 100%;
  height: 75%;
  top: 0;
  left: 0;
  background-color: white;
  border-top: 2px solid #ddd;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  padding: 15px;
  overflow-y: auto;
}

.more-box ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.more-box ul li {
  display: flex;
  align-items: center;
  font-size: 1.1rem;
  padding: 10px 0;
  border-bottom: 1px solid #eee;
  cursor: pointer;
}

.more-box ul li i {
  font-size: 1.5rem;
  margin-right: 10px;
  color: #28a745;
}

.more-icon {
  background: none;
  border: none;
  font-size: 1rem;
  color: inherit;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.more-icon i {
  font-size: 1.5rem;
  margin-right: 5px;
}

.nav-link.more-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0px;
  padding: 1px;
  background: none;
  border: none;
  color: inherit;
  font-size: 12px;
  text-align: center;
  cursor: pointer;
  margin-left: 10px;
}

.nav-link.more-button i {
  font-size: 20px;
  line-height: 1;
}

.nav-link.more-button span {
  font-size: 12px;
  margin-top: 2px;
}

@media (min-width: 769px) {
  .nav-link.more-button {
    flex-direction: row;
    align-items: center;
    justify-content: start;
    gap: 10px;
  }

  .nav-link.more-button i {
    font-size: 22px;
  }

  .nav-link.more-button span {
    font-size: 15px;
    margin-top: 0;
    font-weight: bold;
  }
}

@media (max-width: 768px) {
  .nav-link.more-button i {
    font-size: 18px;
  }

  .nav-link.more-button span {
    font-size: 10px;
    margin-top: 1px;
  }
}