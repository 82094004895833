.blog-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
  margin-top: 70px;
}

.blogPost-title {
  font-size: 3rem;
  text-align: center;
  margin-bottom: 2rem;
  color: #333;
}

.blog-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
}

.blog-card {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.blog-image {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.blog-content {
  padding: 1.5rem;
}

.blog-post-title {
  font-size: 1.5rem;
  margin-bottom: 1rem;
  color: #333;
}

.blog-excerpt {
  font-size: 1rem;
  color: #666;
  margin-bottom: 1rem;
}

.blog-meta {
  display: flex;
  justify-content: space-between;
  font-size: 0.9rem;
  color: #888;
  margin-bottom: 1rem;
}

.blog-read-more {
  background-color: #4caf50;
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

.blog-read-more:hover {
  background-color: #45a049;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: white;
  padding: 2rem;
  border-radius: 8px;
  max-width: 800px;
  width: 90%;
  max-height: 90vh;
  overflow-y: auto;
  position: relative;
}

.modal-close {
  position: absolute;
  top: 1rem;
  right: 1rem;
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  color: #333;
}

.modal-image {
  width: 100%;
  max-height: 400px;
  object-fit: cover;
  border-radius: 8px;
  margin-bottom: 1rem;
}

.modal-excerpt {
  font-size: 1.1rem;
  color: #666;
  margin-bottom: 1rem;
}

.modal-meta {
  display: flex;
  justify-content: space-between;
  font-size: 0.9rem;
  color: #888;
  margin-bottom: 1rem;
}

.modal-full-content {
  font-size: 1rem;
  color: #333;
  line-height: 1.6;
}

@media (max-width: 768px) {
  .blog-title {
    font-size: 2rem;
  }

  .blog-grid {
    grid-template-columns: 1fr;
  }

  .blog-post-title {
    font-size: 1.3rem;
  }

  .blog-excerpt {
    font-size: 0.9rem;
  }

  .blog-meta {
    flex-direction: column;
    align-items: flex-start;
  }

  .blog-meta span {
    margin-bottom: 0.5rem;
  }

  .modal-content {
    padding: 1.5rem;
  }
}

@media (max-width: 480px) {
  .blog-title {
    font-size: 1.8rem;
  }

  .blog-post-title {
    font-size: 1.2rem;
  }

  .blog-excerpt {
    font-size: 0.8rem;
  }

  .blog-read-more {
    font-size: 0.9rem;
  }

  .modal-excerpt {
    font-size: 1rem;
  }

  .modal-full-content {
    font-size: 0.9rem;
  }
}
