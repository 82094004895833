.signup-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background-color: #f0f8ea;
  font-family: "Roboto", sans-serif;
  color: #333;
  font-size: 16px;
  padding: 20px;
  box-sizing: border-box;
}

.signup-container {
  display: flex;
  width: 100%;
  max-width: 1000px;
  margin: auto;
  background-color: #fff;
  border-radius: 20px;
  overflow: hidden;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  animation: fadeIn 0.5s ease-out;
  min-height: 400px;
  margin-top: 30px;
}

.signup-form-container {
  flex: 1;
  padding: 40px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: padding 0.3s ease;
}

.signup-form-container h1 {
  color: #2c5e1a;
  margin-bottom: 30px;
  font-size: 28px;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 1px;
  transition: font-size 0.3s ease;
}

.signup-form .form-group {
  margin-bottom: 25px;
  position: relative;
  transition: margin 0.3s ease;
}

.signup-form input[type="text"],
.signup-form input[type="email"],
.signup-form input[type="password"],
.signup-form input[type="tel"] {
  width: 100%;
  padding: 15px 15px 15px 40px;
  border: 2px solid #e0f2d9;
  border-radius: 10px;
  font-size: 16px;
  transition: all 0.3s ease;
  box-sizing: border-box;
  background-color: #f9fcf8;
}

.signup-form input:focus {
  border-color: #4caf50;
  box-shadow: 0 0 15px rgba(76, 175, 80, 0.2);
  outline: none;
  background-color: #ffffff;
  transform: translateY(-2px);
}

.input-icon {
  position: absolute;
  left: 12px;
  top: 50%;
  transform: translateY(-50%);
  color: #4caf50;
  font-size: 18px;
  transition: all 0.3s ease;
}

.signup-form input:focus + .input-icon {
  color: #45a049;
  transform: translateY(-50%) scale(1.1);
}

.signup-button {
  background-color: #4caf50;
  color: white;
  border: none;
  padding: 15px 20px;
  border-radius: 10px;
  font-size: 18px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  width: 100%;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-top: 30px;
}

.signup-button:hover {
  background-color: #45a049;
  transform: translateY(-3px);
  box-shadow: 0 7px 20px rgba(76, 175, 80, 0.3);
}

.signup-button:active {
  transform: translateY(-1px);
  box-shadow: 0 3px 10px rgba(76, 175, 80, 0.2);
}

.error-message,
.success-message {
  font-size: 14px;
  margin-top: 8px;
  font-weight: 500;
  transition: all 0.3s ease;
}

.error-message {
  color: #ff6b6b;
}
.success-message {
  color: #4caf50;
}

.terms-text,
.login-link {
  font-size: 14px;
  color: #666;
  margin-top: 20px;
  text-align: center;
  transition: all 0.3s ease;
}

.terms-text a,
.login-link a {
  color: #4caf50;
  text-decoration: none;
  font-weight: 600;
  transition: all 0.3s ease;
}

.terms-text a:hover,
.login-link a:hover {
  color: #45a049;
  text-decoration: underline;
}

.signup-image {
  flex: 1;
  background-image: url("https://img.freepik.com/premium-photo/closeup-shopping-cart-filled-with-diverse-groceries_1198884-54600.jpg?w=740");
  background-size: cover;
  background-position: center;
  position: relative;
  transition: all 0.3s ease;
}

.signup-image::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(
    135deg,
    rgba(76, 175, 80, 0.6) 0%,
    rgba(44, 94, 26, 0.6) 100%
  );
  transition: opacity 0.3s ease;
}

@media (max-width: 768px) {
  .signup-page {
    padding: 20px 15px;
  }

  .signup-container {
    flex-direction: column;
    max-height: none;
    border-radius: 15px;
  }

  .signup-form-container {
    padding: 30px 20px;
  }

  .signup-image {
    height: 150px;
    order: -1;
  }

  .signup-form-container h1 {
    font-size: 24px;
    margin-bottom: 25px;
  }

  .signup-form .form-group {
    margin-bottom: 20px;
  }

  .signup-form input[type="text"],
  .signup-form input[type="email"],
  .signup-form input[type="password"],
  .signup-form input[type="tel"] {
    padding: 12px 12px 12px 40px;
    font-size: 16px;
  }

  .input-icon {
    font-size: 18px;
    left: 12px;
  }

  .signup-button {
    padding: 12px 18px;
    font-size: 16px;
    margin-top: 25px;
  }

  .terms-text,
  .login-link {
    font-size: 14px;
    margin-top: 20px;
  }
}

@media (max-width: 480px) {
  .signup-page {
    padding: 15px 10px;
  }

  .signup-form-container {
    padding: 20px 15px;
  }

  .signup-form-container h1 {
    font-size: 22px;
    margin-bottom: 20px;
  }

  .signup-form input[type="text"],
  .signup-form input[type="email"],
  .signup-form input[type="password"],
  .signup-form input[type="tel"] {
    padding: 10px 10px 10px 35px;
    font-size: 14px;
  }

  .input-icon {
    font-size: 16px;
    left: 10px;
  }

  .signup-button {
    padding: 10px 15px;
    font-size: 15px;
    margin-top: 20px;
  }

  .terms-text,
  .login-link {
    font-size: 12px;
    margin-top: 15px;
  }
}

/* Smooth animation for form elements */
.signup-form input,
.signup-button,
.terms-text,
.login-link {
  animation: fadeInUp 0.5s ease-out;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
