/* General styles */
.vegetables-app {
  font-family: "Arial", sans-serif;
  color: #333;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

/* Vegetable Categories */
.vegetables-categories {
  margin-bottom: 40px;
}

.vegetables-categories h2 {
  font-size: 2em;
  margin-bottom: 20px;
  color: #2c3e50;
}

.vegetables-category-buttons {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 20px;
}

.vegetables-category-buttons button {
  padding: 10px 20px;
  background-color: #ecf0f1;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  transition: background-color 0.3s;
}

/* Vegetables Grid */
.vegetables-grid {
  margin-top: 20px;
}

.vegetables-grid-container {
  display: grid; /* Use CSS Grid for layout */
  grid-template-columns: repeat(
    2,
    1fr
  ); /* Default: 2 columns for all screen sizes */
  gap: 20px; /* Space between items */
}

.vegetables-item {
  background-color: #ffffff;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s;
}

.vegetables-item:hover {
  transform: translateY(-5px);
}

.vegetables-image {
  width: 100%; /* Make image take full width */
  height: 150px; /* Set a fixed height for the images */
  object-fit: cover; /* Maintain aspect ratio */
}

/* Vegetable Details */
.vegetables-item .vegetables-name {
  font-size: 1.1em;
  margin: 10px;
  color: #2c3e50;
}

.vegetables-price {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px;
}

.vegetables-current-price {
  font-size: 1.2em;
  font-weight: bold;
  color: #e74c3c; /* Red color for current price */
}

.vegetables-item button {
  display: block;
  width: calc(100% - 20px);
  margin: 10px;
  padding: 10px;
  background-color: #3498db;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.vegetables-item button:hover {
  background-color: #2980b9;
}

/* Media Queries for Responsive Design */
@media (max-width: 768px) {
  .vegetables-grid-container {
    grid-template-columns: repeat(
      2,
      1fr
    ); /* Ensure 2 columns for smaller screens */
  }
}

/* Media Query for Laptops */
@media (min-width: 769px) {
  .vegetables-grid-container {
    grid-template-columns: repeat(
      4,
      1fr
    ); /* Change to 4 columns for larger screens */
  }

  .vegetables-image {
    height: 150px; /* Keep height constant for laptop view */
  }
}

/* Show More Button */
.show-more-button {
  margin: 20px auto;
  padding: 10px 20px;
  background-color: #3498db;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  display: block;
}

.show-more-button:hover {
  background-color: #2980b9;
}

.quantity-box {
  display: flex;
  align-items: center;
  background-color: #3498db; /* Blue background color */
  padding: 5px 10px;
  border-radius: 5px;
  color: white; /* Text color inside the box */
  margin-top: 10px; /* Space above the quantity box */
}

.increment-button,
.decrement-button {
  cursor: pointer;
  padding: 0 5px; /* Space around the buttons */
}

.quantity {
  margin: 0 5px; /* Space around the quantity text */
}
