.tracking-widget {
  position: fixed;
  bottom: 85px;
  right: 20px;
  z-index: 1000;
}

.tracking-widget-button {
  background: linear-gradient(135deg, #007bff 0%, #007bff 100%);
  color: rgb(255, 255, 255);
  border: none;
  width: 44px;
  height: 44px;
  border-radius: 16px;
  cursor: pointer;
  box-shadow: 0 4px 12px rgba(0, 123, 255, 0.25);
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  z-index: 1000;
  backdrop-filter: blur(8px);
}

.tracking-widget-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 20px rgba(0, 123, 255, 0.35);
  background: linear-gradient(135deg, #007bff 0%, #0056b3 100%);
}

.tracking-widget-button svg {
  width: 22px !important;
  height: 22px !important;
  filter: drop-shadow(0 1px 2px rgba(0, 0, 0, 0.1));
}

/* Updated pulse animation */
@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(0, 123, 255, 0.4);
  }

  70% {
    box-shadow: 0 0 0 10px rgba(0, 123, 255, 0);
  }

  100% {
    box-shadow: 0 0 0 0 rgba(0, 123, 255, 0);
  }
}

.tracking-widget-button {
  animation: pulse 2s infinite;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(15, 23, 42, 0.65);
  backdrop-filter: blur(4px);
  z-index: 1000;
}

.tracking-widget-modal {
  position: fixed;
  right: 20px;
  bottom: 140px;
  width: 320px;
  max-height: 480px;
  background: white;
  border-radius: 12px;
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
    0 10px 10px -5px rgba(0, 0, 0, 0.04);
  overflow: hidden;
  z-index: 1001;
  scrollbar-width: none;
  /* Firefox */
  -ms-overflow-style: none;
  /* IE and Edge */
}

.tracking-widget-modal::-webkit-scrollbar {
  display: none;
  /* Chrome, Safari and Opera */
}

.tracking-widget-header {
  background: #4f46e5;
  color: white;
  padding: 12px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.tracking-widget-header h3 {
  margin: 0;
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 0.2px;
}

.close-button {
  background: none;
  border: none;
  color: white;
  font-size: 20px;
  cursor: pointer;
  padding: 0;
  opacity: 0.8;
  transition: opacity 0.2s;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.close-button:hover {
  opacity: 1;
}

.tracking-widget-form {
  padding: 16px;
}

.tracking-input {
  width: 100%;
  padding: 10px 12px;
  margin-bottom: 12px;
  border: 1.5px solid #e2e8f0;
  border-radius: 8px;
  font-size: 14px;
  transition: all 0.2s ease;
  outline: none;
  background: white;
}

.tracking-input:focus {
  border-color: #4f46e5;
  box-shadow: 0 0 0 2px rgba(79, 70, 229, 0.1);
}

.tracking-input.error {
  border-color: #ef4444;
  background-color: #fff5f5;
}

.tracking-input:disabled {
  background-color: #f8fafc;
  cursor: not-allowed;
  opacity: 0.7;
}

.error-message {
  color: #ef4444;
  font-size: 12px;
  margin: -8px 0 12px;
  padding: 0 2px;
}

.submit-button {
  width: 100%;
  padding: 10px 12px;
  background: #4f46e5;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.submit-button:hover:not(:disabled) {
  background: #4338ca;
}

.submit-button:disabled {
  background: #e2e8f0;
  cursor: not-allowed;
}

.tracking-content {
  padding: 12px;
  overflow-y: auto;
  scrollbar-width: none;
  /* Firefox */
  -ms-overflow-style: none;
  /* IE and Edge */
}

.tracking-content::-webkit-scrollbar {
  display: none;
  /* Chrome, Safari and Opera */
}

.order-list {
  margin-bottom: 8px;
}

.order-select {
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-bottom: 10px;
}

.order-select option[value="signin"] {
  background-color: #f5f5f5;
  font-style: italic;
  color: #666;
  border-top: 1px solid #ddd;
  padding-top: 8px;
  margin-top: 8px;
}

.tracking-iframe-container {
  height: 280px;
  border-radius: 8px;
  overflow: hidden;
  border: 1.5px solid #e2e8f0;
  background: #f8fafc;
  -webkit-overflow-scrolling: touch;
  /* Smooth scrolling on iOS */
}

.tracking-iframe {
  width: 100%;
  height: 100%;
  border: none;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.tracking-iframe::-webkit-scrollbar {
  display: none;
}

/* Loading spinner */
.spinner {
  animation: spin 1s linear infinite;
  width: 16px;
  height: 16px;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

/* Global scrollbar hiding for the widget and all its children */
.tracking-widget *,
.tracking-widget-modal,
.tracking-content,
.tracking-iframe-container,
.tracking-iframe {
  scrollbar-width: none !important;
  -ms-overflow-style: none !important;
}

.tracking-widget *::-webkit-scrollbar,
.tracking-widget-modal::-webkit-scrollbar,
.tracking-content::-webkit-scrollbar,
.tracking-iframe-container::-webkit-scrollbar,
.tracking-iframe::-webkit-scrollbar {
  width: 0 !important;
  height: 0 !important;
  display: none !important;
  background: transparent !important;
}

/* Mobile Responsive Styles */
@media screen and (max-width: 768px) {
  .modal-backdrop {
    background: rgba(15, 23, 42, 0.75);
    backdrop-filter: blur(8px);
    -webkit-backdrop-filter: blur(8px);
    overflow: hidden;
  }

  .tracking-widget {
    bottom: 85px;
    right: 16px;
  }

  .tracking-widget-button {
    width: 40px;
    height: 40px;
    border-radius: 14px;
  }

  .tracking-widget-button svg {
    width: 16px;
    height: 16px;
  }

  .tracking-widget-modal {
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    max-width: 100%;
    margin: 0;
    border-radius: 28px 28px 0 0;
    background: #ffffff;
    box-shadow: 0 -8px 30px rgba(0, 0, 0, 0.15);
    max-height: 85vh;
    transform: translateY(0);
    transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    overflow-y: auto;
    overscroll-behavior: contain;
    -webkit-overflow-scrolling: touch;
  }

  .tracking-content {
    padding: 24px;
    background: #ffffff;
    overflow-y: auto;
    overscroll-behavior: contain;
    -webkit-overflow-scrolling: touch;
  }

  .tracking-iframe-container {
    height: 45vh;
    max-height: 400px;
    min-height: 300px;
    border-radius: 20px;
    border: 2px solid #e2e8f0;
    background: #ffffff;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.03);
    overflow: hidden;
    -webkit-overflow-scrolling: touch;
    overscroll-behavior: contain;
  }

  .tracking-widget-header {
    background: #ffffff;
    color: #1e293b;
    padding: 20px 24px 16px;
    position: relative;
    border-bottom: 1px solid rgba(226, 232, 240, 0.8);
  }

  .tracking-widget-header:before {
    content: '';
    position: absolute;
    top: 8px;
    left: 50%;
    transform: translateX(-50%);
    width: 48px;
    height: 5px;
    background: #e2e8f0;
    border-radius: 100px;
  }

  .tracking-widget-header h3 {
    font-size: 18px;
    font-weight: 700;
    color: #0f172a;
    text-align: center;
    width: 100%;
    letter-spacing: -0.02em;
    margin-top: 8px;
  }

  .close-button {
    position: absolute;
    right: 16px;
    top: 50%;
    transform: translateY(-50%);
    color: #64748b;
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 12px;
    background: #f8fafc;
    transition: all 0.2s ease;
    margin-top: 4px;
  }

  .close-button:hover {
    background: #f1f5f9;
    color: #334155;
  }

  .tracking-widget-form {
    padding: 24px;
    background: #ffffff;
  }

  .tracking-input {
    padding: 16px;
    border: 2px solid #e2e8f0;
    border-radius: 16px;
    font-size: 16px;
    background: #ffffff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.02);
    transition: all 0.2s ease;
    font-weight: 500;
  }

  .tracking-input:focus {
    border-color: #6366f1;
    box-shadow: 0 4px 12px rgba(99, 102, 241, 0.08);
    background: #ffffff;
  }

  .tracking-input::placeholder {
    color: #94a3b8;
    font-weight: 400;
  }

  .submit-button {
    padding: 16px;
    border-radius: 16px;
    font-size: 16px;
    font-weight: 600;
    background: linear-gradient(135deg, #6366f1 0%, #4f46e5 100%);
    box-shadow: 0 4px 12px rgba(79, 70, 229, 0.15);
    margin-top: 12px;
    letter-spacing: -0.01em;
    transition: all 0.2s ease;
    height: 56px;
  }

  .submit-button:hover:not(:disabled) {
    background: linear-gradient(135deg, #4f46e5 0%, #4338ca 100%);
    transform: translateY(-1px);
    box-shadow: 0 6px 16px rgba(79, 70, 229, 0.2);
  }

  .submit-button:disabled {
    background: #e2e8f0;
    opacity: 0.9;
  }

  .order-list {
    margin-bottom: 20px;
  }

  .order-select {
    padding: 16px;
    border-radius: 16px;
    font-size: 15px;
    background: #ffffff;
    border: 2px solid #e2e8f0;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.02);
    color: #0f172a;
    transition: all 0.2s ease;
    font-weight: 500;
    height: 56px;
    -webkit-appearance: none;
    background-image: url("data:image/svg+xml,%3Csvg width='14' height='8' viewBox='0 0 14 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1L7 7L13 1' stroke='%2364748B' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: right 16px center;
    padding-right: 48px;
  }

  .order-select:focus {
    border-color: #6366f1;
    box-shadow: 0 4px 12px rgba(99, 102, 241, 0.08);
  }

  .error-message {
    font-size: 14px;
    color: #ef4444;
    margin: 12px 4px 4px;
    padding: 0 4px;
    display: flex;
    align-items: center;
    gap: 8px;
    font-weight: 500;
  }

  .error-message::before {
    content: '!';
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    background: #fef2f2;
    border-radius: 50%;
    font-size: 12px;
    font-weight: 700;
    color: #ef4444;
  }

  .spinner {
    width: 20px;
    height: 20px;
    border: 2.5px solid rgba(255, 255, 255, 0.3);
    border-radius: 50%;
    border-top-color: #ffffff;
    animation: spin 0.8s linear infinite;
  }
}

/* Small mobile devices */
@media screen and (max-width: 360px) {
  .tracking-widget-modal {
    max-height: 90vh;
  }

  .tracking-widget-header {
    padding: 16px 20px 12px;
  }

  .tracking-widget-form,
  .tracking-content {
    padding: 20px;
  }

  .tracking-input,
  .submit-button,
  .order-select {
    padding: 14px;
    font-size: 15px;
    height: 52px;
  }

  .tracking-iframe-container {
    height: 42vh;
    min-height: 280px;
  }
}

/* Ensure no scrollbars on iOS devices */
@supports (-webkit-touch-callout: none) {

  .tracking-widget *,
  .tracking-widget-modal,
  .tracking-content,
  .tracking-iframe-container,
  .tracking-iframe {
    &::-webkit-scrollbar {
      width: 0 !important;
      height: 0 !important;
      display: none !important;
    }
  }
}
