.signin-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background-color: #f0f8ea;
  font-family: "Roboto", sans-serif;
  color: #333;
  font-size: 16px;
  padding: 20px;
  box-sizing: border-box;
  margin-top: 50px;
}

.signin-container {
  display: flex;
  width: 100%;
  max-width: 1000px;
  margin: auto;
  background-color: #fff;
  border-radius: 20px;
  overflow: hidden;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  animation: fadeIn 0.5s ease-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.signin-form-container {
  flex: 1;
  padding: 3rem;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.signin-image {
  flex: 1;
  background-image: url("https://images.unsplash.com/photo-1542838132-92c53300491e?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1974&q=80");
  background-size: cover;
  background-position: center;
  position: relative;
}

.signin-image::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(
    135deg,
    rgba(76, 175, 80, 0.6) 0%,
    rgba(156, 204, 101, 0.6) 100%
  );
}

.signin-container h1 {
  font-size: 2.5rem;
  margin-bottom: 2rem;
  text-align: center;
  color: #4caf50;
}

.signin-form .form-group {
  margin-bottom: 1.5rem;
  display: flex;
  flex-direction: column;
}

.signin-form input[type="telephone"],
.signin-form input[type="password"] {
  width: 100%;
  padding: 0.75rem;
  border: 2px solid #e0e0e0;
  border-radius: 8px;
  font-size: 1rem;
  transition: all 0.3s ease;
  box-sizing: border-box;
}

.signin-form input[type="email"]:focus,
.signin-form input[type="password"]:focus {
  border-color: #4caf50;
  outline: none;
  box-shadow: 0 0 0 3px rgba(76, 175, 80, 0.2);
}

.signin-button {
  width: 100%;
  padding: 0.75rem;
  background-color: #4caf50;
  color: #fff;
  border: none;
  border-radius: 8px;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease;
  margin-top: 1rem;
}

.signin-button:hover {
  background-color: #45a049;
  transform: translateY(-2px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.signin-button:active {
  transform: translateY(0);
  box-shadow: none;
}

.error-message {
  color: #f44336;
  text-align: center;
  margin-top: 1rem;
  animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  font-size: 0.9rem;
}

@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }
  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }
  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }
  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}

.forgot-password {
  text-align: center;
  margin-top: 1rem;
}

.forgot-password a {
  color: #4caf50;
  text-decoration: none;
  transition: color 0.3s ease;
  font-size: 0.9rem;
}

.forgot-password a:hover {
  color: #45a049;
  text-decoration: underline;
}

.signup-link {
  text-align: center;
  margin-top: 1.5rem;
  font-size: 0.9rem;
}

.signup-link a {
  color: #4caf50;
  text-decoration: none;
  font-weight: bold;
  transition: color 0.3s ease;
}

.signup-link a:hover {
  color: #45a049;
  text-decoration: underline;
}

@media (max-width: 768px) {
  .signin-container {
    flex-direction: column;
  }

  .signin-image {
    height: 200px;
  }

  .signin-form-container {
    padding: 2rem;
  }

  .signin-container h1 {
    font-size: 2rem;
  }
}

@media (max-width: 480px) {
  .signin-form-container {
    padding: 1.5rem;
  }

  .signin-container h1 {
    font-size: 1.8rem;
  }

  .signin-form input[type="email"],
  .signin-form input[type="password"],
  .signin-button {
    font-size: 0.9rem;
  }
}
