.search-item,
.related-item {
  display: flex;
  align-items: center;
  border: 1px solid #ddd;
  padding: 10px;
  margin-bottom: 20px;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.item-image {
  flex: 0 0 100px;
  height: 100px;
  margin-right: 20px;
}

.item-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
}

.item-info {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.item-name {
  font-size: 18px;
  font-weight: bold;
}

.item-size {
  font-size: 14px;
  color: #555;
  margin-bottom: 5px;
}

.item-price {
  font-size: 16px;
  color: #e53935;
  font-weight: bold;
  margin-bottom: 10px;
}

.add-to-cart-button {
  background-color: #28a745;
  color: white;
  border: none;
  padding: 8px 12px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s;
}

.add-to-cart-button:hover {
  background-color: #218838;
}

.quantity-controls {
  display: flex;
  align-items: center;
  margin-top: 5px;
}

.quantity-controls button {
  background-color: #28a745;
  color: #ffffff;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  margin: 0 5px;
}

.related-items {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 5px;
  margin-top: 20px;
  margin-left: 5px;
  margin-right: 10px;
}

.related-items h3 {
  margin-bottom: 20px;
  font-size: 2em;
  color: #2c3e50;
  margin-top: 30px;
}

.related-item {
  display: flex;
  align-items: center;
  padding: 15px;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 10px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.related-item img {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 10px;
  margin-left: 15px;
}

.related-item .item-info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 0px;
}

.add-to-cart-button {
  background-color: #28a745;
  color: white;
  border: none;
  padding: 8px 12px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s;
  margin-right: 25px;
}

.add-to-cart-button:hover {
  background-color: #218838;
}

@media (max-width: 768px) {
  .search-item,
  .related-item {
    flex-direction: column;
    align-items: flex-start;
  }

  .item-image {
    width: 100%;
    height: auto;
    margin-bottom: 10px;
  }

  .item-info {
    width: 100%;
    align-items: flex-start;
  }

  .related-items {
    grid-template-columns: repeat(2, 1fr);
  }

  .search-results {
    margin-top: 95px;
  }

  .search-item {
    max-width: 50%;
    margin: 0 auto;
  }

  .search-results-dropdown {
    max-height: 60vh;
  }

  .search-result-image {
    width: 40px;
    height: 40px;
  }

  .search-result-item {
    padding: 8px;
  }

  .search-result-details > div:nth-child(1) {
    font-size: 0.85rem;
  }

  .search-result-details > div:nth-child(2) {
    font-size: 0.75rem;
  }

  .search-result-details > div:nth-child(3) {
    font-size: 0.85rem;
  }
}

@media (min-width: 769px) {
  .search-results {
    margin-top: 100px;
  }

  .related-items {
    grid-template-columns: repeat(3, 1fr);
  }
}

.search-results-dropdown {
  position: absolute;
  top: calc(100% + 8px);
  left: 0;
  right: 0;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  max-height: 70vh;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 8px;
  border: 1px solid #e0e0e0;
}

.search-result-item {
  display: flex;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #f0f0f0;
  transition: background-color 0.2s ease;
}

.search-result-item:last-child {
  border-bottom: none;
}

.search-result-item:hover {
  background-color: #f9f9f9;
}

.search-result-image {
  width: 50px;
  height: 50px;
  border-radius: 4px;
  object-fit: cover;
  margin-right: 12px;
}

.search-result-details {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 2px;
  min-width: 0;
}

.search-result-details > div {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.search-result-details > div:nth-child(1) {
  font-weight: 600;
  color: #333;
  font-size: 0.9rem;
}

.search-result-details > div:nth-child(2) {
  color: #666;
  font-size: 0.8rem;
}

.search-result-details > div:nth-child(3) {
  color: #00b894;
  font-weight: 600;
  font-size: 0.9rem;
}

.background-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgb(0 0 0 / 0%);
  z-index: 999;
}
