.medicine-info-box {
  background-color: #f9f9f9;
  padding: 20px;
  margin-top: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.medicine-info-row {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.input-group {
  width: 48%;
}

.input-group label {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
}

.input-group input {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  margin-top: 5px;
}

.medicine-actions {
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
}
.or-text {
  font-size: 18px;
  font-weight: bold;
  align-self: center;
  margin: 0 10px;
  color: #777;
  margin-top: 40px;
}
.cart-button,
.add-more-button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
}

.cart-button {
  background-color: #28a745;
  color: white;
}

.cart-button:hover {
  background-color: #218838;
}

.add-more-button {
  background-color: #007bff;
  color: white;
}

.add-more-button:hover {
  background-color: #0056b3;
}

/* Styles for the Medicine Grid */
.medicine-grid-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  margin-top: 30px;
}
/* General styles */
.medicine-app {
  font-family: "Roboto", "Arial", sans-serif;
  color: #333;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f9f9f9;
  margin-top: auto;
  text-align: center;
}

/* Hero Section */
.medicine-hero-section {
  display: flex;
  align-items: center;
  background-color: #e8f4f8;
  border-radius: 12px;
  overflow: hidden;
  margin-bottom: 30px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  height: 150px;
}

.medicine-hero-content {
  flex: 1;
  padding: 15px;
}

.medicine-hero-content h1 {
  font-size: 2em;
  margin-bottom: 8px;
  color: #1a3c54;
  line-height: 1.2;
  font-weight: 700;
}

.medicine-hero-content p {
  font-size: 0.9em;
  color: #34495e;
  line-height: 1.4;
}

/* Categories */
.medicine-categories {
  margin-bottom: 25px;
}

.medicine-categories h2 {
  font-size: 1.6em;
  margin-bottom: 12px;
  color: #1a3c54;
  font-weight: 600;
  margin-top: 80px;
}

.medicine-category-buttons {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-bottom: 15px;
}

.medicine-category-buttons button {
  padding: 6px 12px;
  background-color: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 16px;
  cursor: pointer;
  transition: all 0.3s ease;
  font-size: 0.85em;
  color: #333;
}

.medicine-category-buttons button:hover {
  background-color: #e8f4f8;
  border-color: #1a3c54;
  color: #1a3c54;
}

/* Grid Layout */
.medicine-grid {
  margin-top: 15px;
}

.medicine-grid-container {
  display: grid;
  gap: 12px;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
}

.medicine-item {
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.medicine-item:hover {
  transform: translateY(-3px);
}

.medicine-image {
  width: 100%;
  height: 120px;
  object-fit: cover;
}

.medicine-details {
  padding: 10px;
  display: flex;
  flex-direction: column;
  text-align: center;
  flex-grow: 1;
}

.medicine-name {
  font-size: 0.9em;
  font-weight: 600;
  margin-bottom: 4px;
  text-align: center;
  color: #1a3c54;
}

.medicine-price {
  font-size: 1em;
  font-weight: 700;
  color: #2ecc71;
  margin-bottom: 6px;
  text-align: center;
}

/* Buttons */
.add-to-cart-button,
.quantity-controls button {
  padding: 5px 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-size: 0.8em;
  font-weight: 600;
}

.add-to-cart-button {
  background-color: #2ecc71;
  color: white;
  width: 100%;
  margin-top: auto;
}

.add-to-cart-button:hover {
  background-color: #27ae60;
}

.quantity-controls {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  padding: 3px;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}

.quantity-controls button {
  background-color: transparent;
  color: #1a3c54;
  border: none;
  width: 24px;
  height: 24px;
  font-size: 18px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition:
    color 0.3s ease,
    transform 0.2s ease;
  padding: 0;
  outline: none;
}

.quantity-controls button:hover {
  color: #ffffff;
  transform: scale(1.1);
}

.quantity-controls button:active {
  transform: scale(0.9);
}

.quantity-controls span {
  font-size: 0.9em;
  font-weight: 600;
  color: #1a3c54;
  margin: 0 10px;
  min-width: 20px;
  text-align: center;
}

/* Header */
.medicine-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
  margin-top: 20px;
  cursor: pointer !important;
  margin-top: 6rem;
}

.medicine-header h2 {
  font-size: 1.4em;
  color: #1a3c54;
  margin: 0;
}

.show-more {
  color: #2ecc71;
  text-decoration: none;
  font-weight: 600;
  font-size: 0.85em;
  transition: color 0.3s ease;
}

.show-more:hover {
  color: #27ae60;
}

/* Responsive Design */
@media (max-width: 768px) {
  .medicine-grid-container {
    grid-template-columns: repeat(3, 1fr);
  }

  .medicine-hero-content h1 {
    font-size: 1.6em;
  }

  .medicine-hero-content p {
    font-size: 0.85em;
  }

  .medicine-categories h2 {
    font-size: 1.4em;
  }

  .medicine-category-buttons button {
    padding: 6px 10px;
    font-size: 0.75em;
  }

  .medicine-image {
    height: 80px;
  }

  .medicine-name {
    font-size: 0.85em;
  }

  .medicine-price {
    font-size: 0.95em;
  }

  .add-to-cart-button,
  .quantity-controls button {
    padding: 4px 8px;
    font-size: 0.75em;
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  .medicine-grid-container {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media (min-width: 1025px) {
  .medicine-grid-container {
    grid-template-columns: repeat(5, 1fr);
  }
}
