.chat-support {
  display: flex;
  flex-direction: column;
  height: 90vh;
  max-width: 450px;
  margin: 20px auto;
  border-radius: 20px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  overflow: hidden;
  transition: all 0.3s ease;
}

.chat-messages {
  flex: 1;
  overflow-y: auto;
  padding: 30px;
  display: flex;
  flex-direction: column;
  background-color: #f7f9fc;
}

.message {
  max-width: 75%;
  padding: 12px 18px;
  margin-bottom: 15px;
  border-radius: 18px;
  font-size: 15px;
  line-height: 1.5;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05);
  animation: fadeIn 0.3s ease;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.user {
  align-self: flex-end;
  background-color: #4a90e2;
  color: white;
  border-bottom-right-radius: 4px;
}

.bot {
  align-self: flex-start;
  background-color: #f0f2f5;
  color: #333;
  border-bottom-left-radius: 4px;
}

.chat-input-form {
  display: flex;
  padding: 20px;
  background-color: #ffffff;
  border-top: 1px solid #e6e9ed;
}

.chat-input {
  flex: 1;
  padding: 12px 18px;
  border: 2px solid #e6e9ed;
  border-radius: 25px;
  font-size: 15px;
  transition: border-color 0.3s ease;
}

.chat-input:focus {
  outline: none;
  border-color: #4a90e2;
}

.chat-submit {
  background-color: #4a90e2;
  color: white;
  border: none;
  padding: 12px 25px;
  margin-left: 15px;
  border-radius: 25px;
  cursor: pointer;
  font-size: 15px;
  font-weight: 600;
  transition:
    background-color 0.3s ease,
    transform 0.1s ease;
}

.chat-submit:hover {
  background-color: #3a7bc8;
}

.chat-submit:active {
  transform: scale(0.98);
}

/* Scrollbar styling */
.chat-messages::-webkit-scrollbar {
  width: 8px;
}

.chat-messages::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 4px;
}

.chat-messages::-webkit-scrollbar-thumb {
  background: #c1c1c1;
  border-radius: 4px;
}

.chat-messages::-webkit-scrollbar-thumb:hover {
  background: #a8a8a8;
}

/* Responsive design */
@media (max-width: 768px) {
  .chat-support {
    height: 100vh;
    max-width: 100%;
    margin: 0;
    border-radius: 0;
  }

  .chat-messages {
    padding: 20px;
  }

  .message {
    max-width: 85%;
  }

  .chat-input-form {
    padding: 15px;
  }

  .chat-input {
    padding: 10px 15px;
    font-size: 14px;
  }

  .chat-submit {
    padding: 10px 20px;
    font-size: 14px;
  }
}

@media (max-width: 480px) {
  .message {
    max-width: 90%;
    font-size: 14px;
  }

  .chat-input-form {
    padding: 10px;
  }

  .chat-input {
    padding: 8px 12px;
  }

  .chat-submit {
    padding: 8px 15px;
  }
}
