.order-history-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.order-list {
  list-style-type: none;
  padding: 0;
}

.order-item {
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 5px;
  margin-bottom: 20px;
  padding: 15px;
}

.item-list {
  list-style-type: disc;
  margin-left: 20px;
}
